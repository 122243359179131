var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "copyright" }, [
      _c("span", [
        _vm._v(
          " © " +
            _vm._s(_vm.now) +
            " " +
            _vm._s(_vm.$t("Uni-Voice for UD")) +
            ". All Rights Reserved. "
        ),
        _vm.env !== "production"
          ? _c("span", [_vm._v("(" + _vm._s(_vm.env) + ")")])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }