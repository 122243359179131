//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixinDevice } from '@/utils/mixin';
import GlobalFooter from '@/components/GlobalFooter';

export default {
  components: {
    GlobalFooter
  },
  mixins: [mixinDevice],
  computed: {
    companyTypeList() {
      return process.env.VUE_APP_COMPANY_TYPE.split(' ');
    },
    companySystem() {
      if (this.companyTypeList.includes('PUSH_ONLY')) {
        return this.$t('Company Push Only');
      }
      if (this.companyTypeList.includes('FULL_ACCESS')) {
        return this.$t('Company Full Access');
      }
      return '';
    },
    isFullAccess() {
      if (this.companyTypeList.includes('FULL_ACCESS')) {
        return true;
      }
      return false;
    },
    logoLanguage() {
      if (this.$i18n.locale === 'ja') {
        return 'Japan';
      }
      return 'English';
    }
  },
  methods: {}
};
