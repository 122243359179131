//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      now: new Date().getFullYear(),
      env: process.env.NODE_ENV
    };
  }
};
